import React,{Fragment, useState} from 'react'
import axios from "axios";
import {Link, navigate} from 'gatsby'
import Seo from "../../components/seo/seo";
import TextBox from "../../components/inputs/Textbox";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
import logo from '../../assets/img/virsat-logo-light.svg'
import { StaticImage } from "gatsby-plugin-image";

function ForTesting({location}) {
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});

  const [txtValues, setTxtValues] = useState({
    name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTxtValues({ ...txtValues, [name]: value });
  };

  const sendData = async () => {
    setSending(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const data = {...txtValues}

    const body = JSON.stringify({ ...data });

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/for-testing`,
        body,
        config
      );
      
      if (res.data.status) {
        resetValues()
        const details = "Success Message"
        navigate("/lp/thank-you-form/", {
          state:{details}
        })
      } else {
        setErrors(res.data.errors);
      }
      
      setSending(false);
    } catch (err) {
      setSending(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };

  const handleClose = () => {
    setErrors({});
  };

  const resetValues = ()=> {
    setErrors({});
    setTxtValues({
      name: "",
    });
  }

  return (
    <Fragment>
      {sending && <Loading message="Please wait while sending your request." />}
      {errors.send_mail && <ErrorMessage handleClose={handleClose} />}
      <Seo
        title='For Testing Only'
        description='For testing only'
      />
      
      <div className="main-container newsletter-lp">
        <div className="newsletterlp-bg">
          <StaticImage
            src='../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT Newsletter Background'
            placeholder='blurred'
            objectFit
            quality="100"
          />
        </div>
        <div className="row">
          <div className="column show-overflow">
            <div className="form-bg-container">
              <StaticImage
                src='../../assets/img/pages/newsletter/virsat-form-newsletter-bg.jpg'
                alt='VIRSAT Logo'
                placeholder='blurred'
                objectFit
                quality="100"
                style={{borderRadius:"15px"}}
                imgStyle={{borderRadius:"15px"}}
              />
            </div>
            <div className="logo-container">
              <Link to="/">
                <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
              </Link>
            </div>
            <div className="form-container">
              <h1 className="main-title small light">Test</h1>
              
              <form action='' method='post' onSubmit={handleSubmit}>
                <div className='label-container'>
                  <TextBox
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Your Name*'
                    txtBoxClass='regular-text'
                    onChange={handleInputChange}
                    value={txtValues.name}
                    required
                    error={errors.name}
                  />
                  {errors.name && (
                    <div className='field-error secondary-color'>{errors.name[0]}</div>
                  )}
                </div>
                
                <button type='submit' className="full-width">Test</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ForTesting
